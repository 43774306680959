import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import '../App.scss';
import HomePage from '../pages/home/home';
import Explore from '../pages/explore/explore';
import Marketplace from '../pages/usermarketplace/marketplace';
import AboutPage from '../pages/about/about';
import DistrictInformation from '../pages/district-information/district-information';
import LicenseInformation from '../pages/license-information/license-information';
import PurchasePage from '../pages/purchase/purchase';
import LoginPage from '../pages/admin/Login';
import Admin from '../pages/admin/Admin';
import { Countdown } from '../pages/countdown';
import ConnectPage from '../pages/connect/connect';
import ProfilePage from '../pages/profile/profile';
import { useAdminContext } from '../store/store-context';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function BaseRoot(props) {
	const { AdminInfo } = useAdminContext();
return <Countdown />;
}
export default BaseRoot;
