import React, { useEffect, useCallback, useState } from "react";
import BaseRoot from "./routes/base-root";
import "./assets/styles/general.scss";
import AOS from "aos";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useMoralis, useMoralisQuery } from "react-moralis";
import moralis from "moralis";
import { StoreProvider, AdminProvider } from "./store/store-context";
import "aos/dist/aos.css";
import "./App.scss";

function App() {

	useEffect(() => {
		AOS.init({
			duration: 1200,
		});
	}, []);


	const [setLoading] = useState(false);
	const [NFTs, setNFTs] = useState([]);
	const [AdminInfo, setAdminInfo] = useState(false);
	const { data } = useMoralisQuery("LicensesForSells", (query) =>
		query.limit(10000))
	const { user, isAuthenticated } = useMoralis();

	const whitelistFalse = useCallback(async () => {
		const users = moralis.Object.extend("User");
		const query = new moralis.Query(users);
		query.equalTo("ethAddress", user?.attributes.ethAddress);

		const results = await query.find();

		const whitelist = moralis.Object.extend("WhitelistedUsers");

		const query1 = new moralis.Query(whitelist);

		query1.equalTo("ethAddress", user?.attributes.ethAddress);
		const results1 = await query1.find();

		if (results1.length !== 0) {
			results[0]?.set("whitelisted", true);
			results[0]?.set("QtyP", results1[0]?.attributes.PremiumLicenses);
			results[0]?.set("QtyE", results1[0]?.attributes.ExclusiveLicenses);
		} else {
			results[0]?.set("whitelisted", false);
			results[0]?.set("QtyP", 1);
			results[0]?.set("QtyE", 1);
		}
		results[0]?.save()
	}, [user?.attributes.ethAddress]);



	const getFirstNFT = useCallback(async () => {

		const allData = data.map((item) => item.attributes);

		const filterData = allData.filter((item) => item.type);
		setNFTs(filterData);
	}, [data]);


	useEffect(() => {
		getFirstNFT()

	}, [getFirstNFT])




	useEffect(() => {
		if (isAuthenticated) {
			whitelistFalse();
		}
		if (
			!AdminInfo &&
			window.location.pathname === "/admin-licenses2022"
		) {
			window.location.assign("/login");
		}
	}, [isAuthenticated, AdminInfo, whitelistFalse]);

	const serverUrl = process.env.REACT_APP_MORALIS_SERVER_URL;

	const appId = process.env.REACT_APP_APPLICATION_ID;

	moralis.start({ serverUrl, appId });

	return (
		<StoreProvider.Provider value={{ NFTs: NFTs, loading: false, setLoading }}>
			<AdminProvider.Provider value={{ AdminInfo, setAdminInfo }}>
				<ToastContainer theme="dark" />
				<BaseRoot />
			</AdminProvider.Provider>
		</StoreProvider.Provider>
	);
}

export default App;