import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import { Triangle } from "react-loader-spinner";
import Footer from "../../components/footer";
import ExclusiveLicenseCard from "../../components/exclusive-license-card";
import PremiumLicenseCard from "../../components/premium-license-card";
import { partners } from "./home-data";
import { useLicensesHook } from "../../hooks/useLicenses";
import {
	heroImage,
	heroImageMobile,
	licenseBgPattern,
	howToPly,
	howToCreateAccount,
	howToPurchase,
	howToView,
} from "../../assets/images";
import { useStoreContext } from "../../store/store-context";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import "../../assets/styles/pages.scss";

function HomePage() {
	const { NFTs, loading } = useStoreContext();

	const { _ExclusiveLicenses, _PremiumLicenses } = useLicensesHook(NFTs);

	const goToLink = (link) => {
		if (link) {
			window.open(link);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="home">
			<div className="hero">
				<Header />
				<div className="sub-header"></div>
				<div className="inner-hero">
					<div className="hero-spread">
						<div className="max400" data-aos="flip-left">
							<div className="max250 w70 imh for-mobile pb-3">
								<img src={heroImageMobile} alt="" />
							</div>
							<p className="mb-0 increased">Welcome to</p>
							<h1 className="large special-font">SINVERSE</h1>
							<h1 className="sub-large special-font">LICENSE OFFICE</h1>
							<div className="for-mobile py-2"></div>
							<p className="">
								Owning metaverse land in SinVerse has some serious benefits and
								provides unlimited potential for the landowner. With SinVerse
								business licenses, you can shape your land into whatever you
								desire, and create and operate a real business backed by
								technology in a groundbreaking new metaverse. Build your
								business empire and become a kingpin with business licenses in
								the SinVerse.
							</p>
							<div className="mt-3">
								<Link to="explore">
									<button className="solid-button-n2 increased-x font-weight-bold px-5">
										<span className="px-2"></span> Explore & Buy Licenses{" "}
										<span className="px-2"></span>
									</button>
								</Link>
							</div>
						</div>
						<div className="max450 imh for-web" data-aos="zoom-in">
							<img src={heroImage} alt="" />
						</div>
					</div>
				</div>
			</div>

			<div className="license-sect py-5">
				<div className="bg-top">
					<img src={licenseBgPattern} alt="" />
				</div>
				<div className="bg">
					<img src={licenseBgPattern} alt="" />
				</div>
				<div className="bg-bottom">
					<img src={licenseBgPattern} alt="" />
				</div>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Triangle height={80} width={80} color="#E7324E" />
					</div>
				) : (
					<div className="w90 center-mobile max1100 py-5">
						<div className="row pt-3">
							<div className="col-md-12">
								<h2 className="font-weight-bold">Exclusive License</h2>
							</div>
							{_ExclusiveLicenses.map((license) => (
								<div
									className="col-lg-3 col-md-6 py-3 mb-4"
									data-aos="fade-up"
									data-aos-delay={200}
								>
									<ExclusiveLicenseCard data={license} />
								</div>
							))}
						</div>
						<div className="row pt-3">
							<div className="col-md-12">
								<h2 className="font-weight-bold">Premium License</h2>
							</div>
							{_PremiumLicenses.map((license) => (
								<div
									className="col-lg-3 col-md-6 py-3 mb-4"
									data-aos="fade-up"
									data-aos-delay={200}
								>
									<PremiumLicenseCard data={license} />
								</div>
							))}
						</div>
					</div>
				)}
			</div>

			<div className="how-to-pattern py-5">
				<div className="w96 max1000 py-5 text-center">
					<h1 className="font-weight-bold">How To Navigate</h1>
					<div className="spread-info pt-3">
						<div
							className="imh max300 for-web"
							data-aos="zoom-in"
							data-aos-delay="500"
						>
							<img src={howToPly} alt="" />
						</div>
						<div className="max500" data-aos="fade-up">
							<div className="steps w96 my-4">
								<div className="imh">
									<img src={howToCreateAccount} alt="" />
								</div>
								<div className="center-info-fill-front">
									<p className="mb-0 text-left pl-3">
										Create an account and connect to your wallet provider
									</p>
								</div>
							</div>
							<div className="steps w96 my-4 py-3">
								<div className="imh">
									<img src={howToView} alt="" />
								</div>
								<div className="center-info-fill-front">
									<p className="mb-0 text-left pl-3">
										View and Engage your favourite NFTs
									</p>
								</div>
							</div>
							<div className="steps w96 my-4">
								<div className="imh">
									<img src={howToPurchase} alt="" />
								</div>
								<div className="center-info-fill-front">
									<p className="mb-0 text-left pl-3">
										Purchase NFTs according to your license type
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="partners relative py-5">
				<div className="abs-bg"></div>
				<div className="w96 max1200 pt-5 relative">
					<h1 className="text-center font-weight-bold">Partners</h1>

					<Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
						{partners.map((item, index) => {
							return (
								<div className="row max1100 w100b py-3" key={index}>
									{item.map((subItem, subIndex) => {
										return (
											<div className="col-md-3 col-6 py-3" key={subIndex}>
												<div
													className={
														(subItem.link ? "clickable hover-zoom" : "") +
														" max200 w90 imh"
													}
												>
													<img
														src={subItem.image}
														alt=""
														onClick={() => goToLink(subItem.link)}
													/>
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</Carousel>
				</div>
			</div>

			<Footer />
		</div>
	);
}
export default HomePage;
