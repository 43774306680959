
import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import SubHeader from "../../components/sub-header";
import VideoModal from "../../components/video-modal";

import { topic, introWriteUp, licenseInfoData } from './data';

import "../../assets/styles/pages.scss";

function LicenseInformation() {

  const [modalDisplay, setModalDisplay] = useState(false);
  const [modalLink, setModalLink] = useState('');

  const toggleModal = (mode, link) => {
    setModalDisplay(mode);
    setModalLink(link);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="license-info">
      <Header />
      <div className="header-fill"></div>
      <SubHeader topic={topic} intro={introWriteUp} />
      <div className="info py-5">
        <div className="w80 max400">
          <div className="imh py-3" onClick={() => toggleModal(true, licenseInfoData.license.videoLink)}>
            <img src={licenseInfoData.license.image} alt="" />
          </div>
        </div>
        <div className="py-3 spread-license max1100 w96">
          <div className="imh max450" data-aos="zoom-in" data-aos-delay="400">
            <img src={licenseInfoData.exclusive.image} alt="" />
          </div>
          <div className="write-up max500" data-aos="fade-up">
            <h3>{licenseInfoData.exclusive.title}</h3>
            <p className="">{licenseInfoData.exclusive.detail}</p>
            <a href={licenseInfoData.exclusive.link} target={'_blank'} rel="noreferrer">
              <button className="solid-button-n">Read More</button>
            </a>
          </div>
        </div>
        <div className="py-3"></div>
        <div className="py-3 spread-license2 max1100 w96">
          <div className="imh max450" data-aos="zoom-in" data-aos-delay="400">
            <img src={licenseInfoData.premium.image} alt="" />
          </div>
          <div className="write-up max500" data-aos="fade-up">
            <h3>{licenseInfoData.premium.title}</h3>
            <p className="">{licenseInfoData.premium.detail}</p>
            <a href={licenseInfoData.premium.link} target={'_blank'} rel="noreferrer">
              <button className="solid-button-n">Read More</button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
      { modalDisplay && <VideoModal onCloseModal={() => toggleModal(false)} link={modalLink} />}
    </div>
  );
}
export default LicenseInformation;
