import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useStoreContext } from '../../store/store-context';
import { toast } from 'react-toastify';
import moralis from 'moralis';
import avatar from '../../assets/images/avatar.jpg';
import MARKETABI from '../../contracts/marketContractAbi.json';
import ERC721ABI from '../../contracts/erc721ContractAbi.json';
import { useMoralis, useMoralisFile } from 'react-moralis';
import validator from 'validator';
import axios from 'axios';
// import { purchasedLicenses } from './data';
import '../../assets/styles/pages.scss';

function ProfilePage() {
	const { user, web3 } = useMoralis();
	const [currentLicense, setCurrentLicense] = useState({});
	const [state, setState] = useState(true);
	const [selfViewMode] = useState(true);
	const { saveFile } = useMoralisFile();

	const [preview, setPreview] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [edit, setEdit] = useState(false);
	const [myItems, setMyItems] = useState('');
	const [onSale, setOnSale] = useState('');
	const [details] = useState('');
	const [disableTime] = useState(false);

	const [purchasePrice, setPurchasePrice] = useState(0);
	const { NFTs } = useStoreContext();
	const [, setData] = useState('');
	const ERC721Contract = new web3.eth.Contract(ERC721ABI, process.env.REACT_APP_ERC721_CONTRACT_ADDRESS);

	ERC721Contract.setProvider(web3.givenProvider);
	const MarketPlaceContract = new web3.eth.Contract(MARKETABI, process.env.REACT_APP_MARKET_CONTRACT_ADDRESS);
	MarketPlaceContract.setProvider(web3.givenProvider);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (preview === '') {
			toast.error('Please Upload image');
		} else if (name === '') {
			toast.error('Input username');
		} else {
			await toast
				.promise(saveFile('nftFile.jpg', preview, { saveIPFS: true }), {
					pending: 'Uploading Image...',
					success: 'Complete',
					error: 'Failed',
				})
				.then((result) => {
					// setValues({ ...values, avatars:  });
					user?.set('avatars', result?._ipfs);
				});

			if (name !== '') {
				user.set('username', name);
			}
			if (!user.get('email')) {
				user.set('email', email);
			}

			user.save().then((res) => {
				toast('Complete');
				window.location.reload();
			});
		}
	};
	const selectLicense = (index) => {
		setCurrentLicense(myItems[index]);
		if (window.innerWidth < 676) {
			const currentLicense = document.getElementById('currentLicense');
			currentLicense.scrollIntoView({
				behavior: 'smooth',
			});
		}
	};
	const selectonSale = (index) => {
		setCurrentLicense(onSale[index]);
		if (window.innerWidth < 676) {
			const currentLicense = document.getElementById('currentLicense');
			currentLicense.scrollIntoView({
				behavior: 'smooth',
			});
		}
	};

	// const delistUserItem = async () => {
	// 	const { data: userLicenses } = await axios(
	// 		`${process.env.REACT_APP_MORALIS_BASE_URL}/${user?.attributes?.ethAddress}/nft?chain=bsc&format=decimal&token_addresses=${process.env.REACT_APP_ERC721_CONTRACT_ADDRESS}`,
	// 		{
	// 			headers: {
	// 				"x-api-key": process.env.REACT_APP_MORALIS_X_API_KEY,
	// 			},
	// 		}
	// 	);

	// 	userLicenses.result.forEach(async (result) => {
	// 		const usere = moralis.Object.extend("LicensesForSells");
	// 		const query = new moralis.Query(usere);
	// 		query.equalTo("tokenId", result.token_id);

	// 		const response = await query.first();
	// 		if (response) {
	// 			response.set("active", false);
	// 			response.save().then(() => {
	// 				console.log("done");
	// 			});
	// 		}
	// 	});
	// };

	const userLicenses = useCallback(async () => {
		if (NFTs) {
			const { data: userLicenses } = await axios(
				`${process.env.REACT_APP_MORALIS_BASE_URL}/${user?.attributes?.ethAddress}/nft?chain=bsc&format=decimal&token_addresses=${process.env.REACT_APP_ERC721_CONTRACT_ADDRESS}`,
				{
					headers: {
						'x-api-key': process.env.REACT_APP_MORALIS_X_API_KEY,
					},
				}
			);

			const res = NFTs?.filter((item) => {
				return userLicenses.result.find(
					(result) =>
						result.token_id === item.tokenId && result.owner_of === user?.attributes.ethAddress && !item.isSold
				);
			});

			const myitem = res.filter((i) => {
				return !i.active;
			});
			const onSale = res.filter((i) => {
				return i.active;
			});
			setMyItems(myitem);
			setOnSale(onSale);
			setData(res);
			return res;
		}
	}, [user?.attributes.ethAddress, NFTs]);

	useEffect(() => {
		window.scrollTo(0, 0);
		userLicenses();
	}, [userLicenses]);

	// const relistAll = async () => {
	// 	const usere = moralis.Object.extend("LicensesForSells");
	// 	const query = new moralis.Query(usere);
	// 	query.equalTo("active", true);
	// 	query.limit(1000);
	// 	const results = await query.find();

	// 	results.map((i) => {
	// 		i.set("hours", "440");
	// 		i.save().then(() => {
	// 			console.log("done");
	// 		});
	// 	});
	// };

	async function Publish(data) {
		if (!validator.isNumeric(purchasePrice)) {
			toast('Invalid purchase price');
		} else {
			await ensureMarketplaceIsApproved(data.tokenId, user?.attributes.ethAddress);

			const res = await toast.promise(
				MarketPlaceContract.methods
					.addItemToMarket(data.tokenId, data.tokenAddress, parseInt(purchasePrice, 10))
					.send({ from: user?.attributes.ethAddress }),
				{
					pending: 'Listing Item',
					success: 'Complete',
					error: 'Failed',
				}
			);
			const params = { uid: `${data?.uid}` };
			await moralis.Cloud.run('delistItem', params);

			const newId = res.events.itemAdded.returnValues.id;
			const usere = moralis.Object.extend('LicensesForSells');
			const query = new moralis.Query(usere);
			query.limit(3000);
			query.equalTo('uid', data?.uid);
			const results = await query.find();
			results[0].set('isSold', true);
			results[0].set('relisted', true);
			results[0].save().then((res) => {
				toast(`Item ${data?.name} listed`);
			});
			const param = { uid: `${newId}` };
			await moralis.Cloud.run('publishItem', param);

			const user1 = moralis.Object.extend('LicensesForSells');
			const query1 = new moralis.Query(user1);
			query1.limit(5000);
			query1.equalTo('uid', newId);
			const results1 = await query1.find();
			results1[0].set('isSold', false);
			results1[0].set('relisted', false);
			results1[0].save().then(() => {
				window.location.reload('');
			});
		}
	}

	async function ensureMarketplaceIsApproved(tokenId, userAddress) {
		const approvedAddress = userAddress;

		if (approvedAddress !== process.env.REACT_APP_MARKET_CONTRACT_ADDRESS) {
			await toast.promise(
				ERC721Contract.methods
					.approve(process.env.REACT_APP_MARKET_CONTRACT_ADDRESS, tokenId)
					.send({ from: userAddress }),
				{
					pending: 'Approving Marketplace Contract...',
					success: 'Complete',
					error: 'Failed',
				}
			);
		}
	}

	const RemoveItem = async (data) => {
		await toast.promise(
			MarketPlaceContract.methods.removeItemFP(data?.uid).send({ from: user?.attributes.ethAddress }),
			{
				pending: 'Delisting item',
				success: 'Complete',
				error: 'Failed',
			}
		);
	};

	return (
		<div className="profile">
			<Header />
			<div className="header-fill"></div>
			<div className="info">
				<div className="w90 max1100">
					<h1 className="text-center font-weight-bold py-5">{edit ? 'Edit Profile' : 'My Profile'}</h1>
					<div className="personal-info">
						<div className="row">
							{!edit ? (
								<>
									<div className="col-xl-3 col-lg-4 col-md-5 px-3 py-2">
										<div className="imh w96 max300">
											<img
												src={user?.attributes?.avatars ? user?.attributes?.avatars : avatar}
												alt=""
												className="rad-10"
											/>
										</div>
									</div>
									<div className="col-xl-9 col-lg-8 col-md-7">
										<div className="row pt-4">
											<div className="col-lg-6">
												<p className="font-weight-bold text-center mb-0">Account</p>
												<p className="text-center">{user?.attributes.ethAddress}</p>
												<p className="font-weight-bold text-center mb-0">Email</p>
												<p className="text-center">
													{user?.attributes.email ? user?.attributes.email : 'No Email Found'}
												</p>
											</div>
											<div className="col-lg-6">
												<p className="font-weight-bold text-center mb-0">Username</p>
												<p className="text-center">
													{user?.attributes.username ? user?.attributes.username : 'No Username Found'}
												</p>
												<div className="mt-4" style={{ marginLeft: '120px', marginTop: '10px' }}>
													<button
														onClick={() => setEdit(!edit)}
														style={{
															width: '50%',
															border: 0,
															backgroundImage: 'linear-gradient(to right, #E7324E, #BA3A7C)',
															padding: '5px 50px',
															margin: '10px',
														}}
													>
														Edit Profile
													</button>
												</div>
											</div>
										</div>
									</div>
								</>
							) : (
								<>
									<div className="col-lg-12">
										<p className="" style={{ fontWeight: 'bold' }}>
											Upload Profile Picture
										</p>
										<div className="col-lg-6">
											<div
												className="upload-space mb-4"
												style={{
													background: 'black',
													borderRadius: '20px',
													padding: '10px',
												}}
											>
												<input
													onChange={(e) => setPreview(e.target.files[0])}
													style={{
														position: 'absolute',
														height: '8%',
														zIndex: '5',
														width: '20%',
														top: '2%',
														left: '42%',
														right: '0',
														bottom: '0',
														opacity: '0',
														cursor: 'pointer',
													}}
													type="file"
												/>
												<div className="text-center">
													<div className="upload-icon shadowed-light">
														<i className="fas fa-cloud-upload-alt"></i>
													</div>
													<p className="mb-0" style={{ color: 'white' }}>
														Click to select a file, or drag it here
													</p>
													<small className="faint-font" style={{ color: 'white' }}>
														PNG, GIF, WEBP or JPG.
													</small>
												</div>
											</div>
											<div className="input-sect">
												<p className="font-weight-bold  mb-0">Email</p>
												<input
													type="email"
													placeholder="Enter Email"
													style={{ color: 'black' }}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
											<div className="input-sect mt-4">
												<p className="font-weight-bold  mb-0">Username</p>
												<input
													type="text"
													placeholder="Enter  name"
													style={{ color: 'black' }}
													onChange={(e) => setName(e.target.value)}
												/>
											</div>
											<div className="col-lg-8">
												<button
													onClick={handleSubmit}
													style={{
														width: '50%',
														border: 0,
														backgroundImage: 'linear-gradient(to right, #E7324E, #BA3A7C)',
														padding: '5px 50px',
														margin: '10px',
													}}
												>
													Submit
												</button>
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="my-3 selecting-sect">
								<div className="selecting-space">
									<div className="px-3 py-3">
										<div className="max400 w90">
											{selfViewMode ? (
												<div className="center-info ">
													<div className="spread-info  ">
														<p className="font-weight-bold increased mb-0 pr-2">My Items</p>
														<div
															className="shadowed select-switch clickable"
															onClick={() => {
																setState(!state);
															}}
														>
															<div className={state ? 'sale-side' : 'item-side'}></div>
														</div>
														<p className="font-weight-bold increased mb-0 pl-2">On Sale</p>
													</div>
												</div>
											) : (
												<h1 className="font-weight-bold text-center">Items</h1>
											)}
										</div>
									</div>
									{/* <button
										onClick={() => delistUserItem()}
										className="hollow-button"
									>
										Delist Items
									</button> */}
									<div className="row">
										{state
											? myItems &&
											  myItems.map((item, index) => {
													return (
														<div className="col-sm-6">
															<div className="item mb-3" key={index}>
																<div
																	className="image-holder"
																	style={{
																		backgroundImage: `url(${item.image})`,
																	}}
																></div>
																<p className="reduced-soft font-weight-bold">{item.name}</p>
																<p className="reduced-soft mb-0">{item.askingPrice} SIN</p>
																<p className="reduced-soft mb-0">{item.description}</p>
																<p className="reduced-soft">{item.available ? 'Available' : 'Not Available'}</p>
																<button className="hollow-button" onClick={() => selectLicense(index)}>
																	View Details
																</button>
															</div>
														</div>
													);
											  })
											: onSale &&
											  onSale.map((item, index) => {
													return (
														<div className="col-sm-6">
															<div className="item mb-3" key={index}>
																<div
																	className="image-holder"
																	style={{
																		backgroundImage: `url(${item.image})`,
																	}}
																></div>
																<p className="reduced-soft font-weight-bold">{item.name}</p>
																<p className="reduced-soft mb-0">{item.askingPrice} SIN</p>
																<p className="reduced-soft mb-0">{item.description}</p>
																<p className="reduced-soft">{item.available ? 'Available' : 'Not Available'}</p>
																<button className="hollow-button" onClick={() => selectonSale(index)}>
																	View Details
																</button>
															</div>
														</div>
													);
											  })}
									</div>
								</div>
							</div>
						</div>
						{currentLicense?.image ? (
							<div className="col-md-6">
								<div id="currentLicense"></div>
								<div className="my-3">
									<div className="item-picture imh">
										<img src={currentLicense.image} alt="" />
									</div>
								</div>
								<div className="my-3">
									<div className="item-detail spread-info-vertical">
										<div>
											<h3 className="font-weight-bold">{currentLicense.askingPrice}</h3>
											<p className="mb-0">{currentLicense.name}</p>
											<p className="">{currentLicense.description}</p>

											{currentLicense.detail && (
												<>
													<p className="font-weight-bold">Details</p>
													<p className="reduced-soft">{currentLicense.detail}</p>
												</>
											)}

											{currentLicense.active === true && (
												<div>
													<button className="solid-button-n" onClick={() => RemoveItem(currentLicense)}>
														Delist Item
													</button>
												</div>
											)}
										</div>
										{currentLicense.active === false ? (
											<>
												<input
													className="reduced-soft"
													type="number"
													placeholder="Enter License Price (SIN)"
													onChange={(event) => setPurchasePrice(event.target.value)}
													style={{
														border: '0',
														width: '100%',
														borderRadius: '5px',
														padding: '12px 5px',
														fontSize: '12px',
														color: 'black',
														marginBottom: '2  0px',
													}}
												/>
												<div className="w100-flat full-button">
													<p
														style={{
															fontSize: '12px',
															fontWeight: 'bold',
															margin: '5px 0px',
														}}
													>
														{details}
													</p>

													<button
														className="solid-button-n"
														onClick={() => Publish(currentLicense)}
														disabled={disableTime}
													>
														Publish
													</button>
												</div>
											</>
										) : null}
									</div>
								</div>
							</div>
						) : (
							<div className="col-md-6 py-5">
								<h2 className="font-weight-bold text-center pt-5">No Item Loaded</h2>
							</div>
						)}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default ProfilePage;
