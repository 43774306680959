import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import {
    heroImage,
    heroImageMobile,
} from "../assets/images";
const utc = require('dayjs/plugin/utc');
var duration = require('dayjs/plugin/duration');


dayjs.extend(utc);
dayjs.extend(duration);

export const Countdown = () => {
    const [countdown, setCountdown] = useState(countdownTo7PMUTC());

    function countdownTo7PMUTC() {
        // Get the current time in UTC
        const currentTime = dayjs().utc();

        // Set the target time to 9 PM UTC
        const targetTime = currentTime.set('hour', 19).set('minute', 0).set('second', 0).set('millisecond', 0);

        // Calculate the duration remaining until the target time
        const durationRemaining = dayjs.duration(targetTime.diff(currentTime));

        // Extract hours, minutes, and seconds from the duration and format them as double digits
        const hoursRemaining = durationRemaining.hours().toString().padStart(2, '0');
        const minutesRemaining = durationRemaining.minutes().toString().padStart(2, '0');
        const secondsRemaining = durationRemaining.seconds().toString().padStart(2, '0');

        return {
            hours: hoursRemaining,
            minutes: minutesRemaining,
            seconds: secondsRemaining
        }
    }

    useEffect(() => {
        // Create an interval to update the countdown every second
        const intervalId = setInterval(() => {
            setCountdown(countdownTo7PMUTC());
        }, 1000);

        // Clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);



    return (
        <div>
            <div className="home">
                <div className="hero">

                    <div className="sub-header"></div>
                    <div className="inner-hero">
                        <div className="hero-spread">
                            <div className="max400" data-aos="flip-left">
                                <div className="max250 w70 imh for-mobile pb-3">
                                    <img src={heroImageMobile} alt="" />
                                </div>
                                <p className="mb-0 increased">Welcome to</p>
                                <h1 className="large special-font">SINVERSE</h1>
                                <h1 className="sub-large special-font">LICENSE OFFICE</h1>
                                <div className="for-mobile py-2"></div>
                                <p className="mt-4">
Business License Sale is now sold out.
                                </p>
                                
                            </div>
                            <div className="max450 imh for-web" data-aos="zoom-in">
                                <img src={heroImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
