import { createContext, useContext } from 'react';
// import { StoresArchive } from './store-archive';
// import {IStore} from './store-interface';

export const StoreProvider = createContext({});
export const AdminProvider = createContext(false);
export const useAdminContext = () => useContext(AdminProvider);
export const useStoreContext = () => useContext(StoreProvider);

// export const StoreContextProvider = ({ children }) => {
//   const [NFTs, setNFTs] = useState({})
//   return (
//     <StoreProvider.Provider >
//       {children}
//     </StoreProvider.Provider>
//   );
// };
