import React from 'react';
import { Link } from 'react-router-dom';
import { capitalizeWords } from '../utils/capitalize';
import { premiumLicenseData } from '../pages/home/home-data';
import '../assets/styles/components.scss';

function PremiumLicenseCard(props) {
	const trimDescription = (value) => `${value.slice(0, 400)} ...`;

	const descriptions = {
		favelas: premiumLicenseData[1].details,
		fashion: premiumLicenseData[0].details,
		harbour: premiumLicenseData[2].details,
	};

	return (
		<div className="w96 max300 p-3 hover-zoom premium-card text-center">
			<div className="imh">
				<img src={props.data ? props.data.image : ''} alt="" />
			</div>
			<h5 className="font-weight-bold">
				{props.data?.district === 'harbour' && 'The'} {capitalizeWords(props.data?.district || props.data.topic)}{' '}
			</h5>
			<p className="mt-3 mb-0 reduced-soft">Business License for {props.data?.district || props.data.topic}</p>
			{props.data.licenseType ? (
				<p className="mb-2 reduced-soft"> {props.data?.licenseType} </p>
			) : (
				<p className="mb-2 reduced-soft">License Type - {props.data?.type} </p>
			)}
			<p className="my-3 reduced fade70 content-height">
				{trimDescription(descriptions[props?.data?.district] || props.data.details)}
			</p>

			<div className={props.data.topic ? 'unclickable' : ''}>
				<Link to={'/purchase-license/premium/' + props.data?.uid || ''}>
					<button className="hollow-button mb-0 w100" disabled>
						Buy License
					</button>
				</Link>
			</div>
		</div>
	);
}
export default PremiumLicenseCard;
